@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  max-width: 1200px !important;
}
.header_section{
  background: #FFFFFF;
  box-shadow: 0px 8px 32px 0px #00000014;
  padding: 10px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
}
.header_section .nav-link{
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  padding: 10px 20px !important;
}
.header_section .navbar-nav{
  margin-left: 30px;
}
.btn_secondary{
  border: 1px solid #2BB673;
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  color: #2BB673;
  padding: 10px 25px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.btn_primary{
  background: linear-gradient(142.04deg, #2BB673 21.91%, #262262 292.83%);
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 10px 25px;
}
.btn_primary_large{
  background: linear-gradient(142.04deg, #2BB673 21.91%, #262262 292.83%);
  border-radius: 12px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 10px 25px;
  width: 100%;
}
.btn_primary_small{
  background: linear-gradient(142.04deg, #2BB673 21.91%, #262262 292.83%);
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 7px 10px;
}
.me-10{
  margin-right: 10px;
}
.banner_section{
  background-image: url('./images/banner_bg_img.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #FBFBFB;
  padding: 180px 0px 100px;

}
.banner_section .text_section h1{
  font-size: 37px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.banner_section .text_section p{
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.how_it_work_section{
  padding: 70px 0px 50px;
}
.how_it_work_section .main_text_section h1{
  font-size: 32px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.how_it_work_section .main_text_section p{
  font-size: 16px;
  line-height: 24px;
  color: #6B6B6B;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 50px;
}
.how_it_work_section .slider_inner_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.how_it_work_section .slider_inner_section .text_section{
  width: 45%;
}
.how_it_work_section .slider_inner_section .text_section h1{
  font-size: 29px;
  line-height: 39px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 5px;
}
.how_it_work_section .slider_inner_section .text_section p{
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}
.how_it_work_section .carousel-control-next, .how_it_work_section .carousel-control-prev{
  width: 4%;
}
.how_it_work_section .carousel-control-prev{
  left: -5%;
}
.how_it_work_section .carousel-control-prev .carousel-control-prev-icon{
  background-image: url('./images/chevron_left.png');
}
.how_it_work_section .carousel-control-next{
  right: -4.5%;
}
.how_it_work_section .carousel-control-next .carousel-control-next-icon{
  background-image: url('./images/chevron_right.png');
}
.features_section{
  background-color: #F6F6F6;
  padding: 70px 0px 80px;
}
.features_section h1{
  font-size: 29px;
  line-height: 39px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 0px;
}
.features_section .card_section{
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.features_section .card_section .icon_section{
  background: #36A2EB;
  border-radius: 50px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.features_section .card_section .text_section{
  width: 80%;
}
.features_section .card_section .text_section h2{
  font-size: 21px;
  line-height: 28px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 0px;
}
.features_section .card_section .text_section p{
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}
.bg_green{
  background: #4BC0C0 !important;
}
.bg_yellow{
  background: #FFBE3F !important;
}
.tryout_verification_section{
  padding: 120px 0px;
}
.tryout_verification_section .text_section h1{
  font-size: 32px;
  line-height: 43px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 10px;
}
.tryout_verification_section .text_section p{
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 40px;
}
.tryout_verification_section .text_section input{
  border: 1px solid #333333;
  width: 50%;
  padding: 4px 10px;
  border-radius: 4px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}
.testimonial_section{
  background: #F6F6F6;
  padding: 80px 0px 100px;
}
.testimonial_section .main_text_section h1{
  font-size: 29px;
  line-height: 43px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.testimonial_section .main_text_section p{
  font-size: 18px;
  line-height: 24px;
  color: #6B6B6B;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 40px;
}
.testimonial_section .slider_inner_section{
  text-align: center;
}
.testimonial_section .slider_inner_section .text_section{
  width: 70%;
  margin: auto;
}
.testimonial_section .slider_inner_section .text_section h1{
  font-size: 29px;
  line-height: 39px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
  font-style: italic;
}
.testimonial_section .slider_inner_section .text_section p{
  font-size: 16px;
  line-height: 21px;
  color: #2BB673;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}
.testimonial_section .carousel-control-prev .carousel-control-prev-icon{
  background-image: url('./images/chevron_big_left.png');
  width: 58px;
  height: 115px;
}
.testimonial_section .carousel-control-next .carousel-control-next-icon{
  background-image: url('./images/chevron_big_right.png');
  width: 58px;
  height: 115px;
}
.cost_calculator_section{
  padding: 120px 0px;
}
.cost_calculator_section .text_section h1{
  font-size: 32px;
  line-height: 43px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 20px;
}
.cost_calculator_section .text_section p{
  font-size: 16px;
  line-height: 21px;
  color: #6B6B6B;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 30px;
}
.cost_calculator_section .form_section label{
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 5px;
}
.cost_calculator_section .form_section .btn_primary_large{
  margin-top: 30px;
}
.cost_calculator_section .form_section .btn_primary_large p{
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  margin: 0px 0px 5px;
}
.cost_calculator_section .form_section .btn_primary_large h1{
  font-size: 48px;
  line-height: 65px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  margin: 0px 0px 5px;
}
.start_using_section{
  background: #F6F6F6;
  padding: 100px 0px;
}
.start_using_section .text_section h1{
  font-size: 37px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 20px;
}
.start_using_section .text_section p{
  font-size: 18px;
  line-height: 24px;
  color: #6B6B6B;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.start_using_section .text_section p img{
  margin-right: 5px;
}
.footer_section{
  background: linear-gradient(148.19deg, #262262 18.21%, #000000 212.6%);
  padding: 70px 0px 150px;
}
.footer_section ul{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.footer_section h2{
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 10px;
}
.footer_section ul li {
  margin-bottom: 10px;

}
.footer_section ul li a{
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  text-decoration: none;
}
.footer_section p{
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 10px;
}
.footer_section p a{
  color: #fff;
  text-decoration: none;
}


/*Privacy Policy Start Css*/

.privacy_policy_page{
  padding: 120px 0px;
}
.privacy_policy_page h1{
  font-size: 32px;
  line-height: 43px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 10px;
}
.privacy_policy_page p{
  font-size: 16px;
  line-height: 21px;
  color: #6B6B6B;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 20px;
}
.privacy_policy_page h3{
  font-size: 18px;
  line-height: 23px;
  color: #6B6B6B;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 10px;
}
.privacy_policy_page ul li{
  font-size: 16px;
  line-height: 21px;
  color: #6B6B6B;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 5px;
}
.footer_privacy_section{
  background: #F6F6F6;
  padding: 15px 0px;
}
.footer_privacy_section p{
  font-size: 16px;
  line-height: 21px;
  color: #6B6B6B;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}

/*Privacy Policy End Css*/

/*Get Started Page Start Css*/

.get_started_page{
  background-color: #FBFBFB;
  background-image: url('./images/banner_bg_img.png');
  background-repeat: no-repeat;
  background-position: top right;
  padding: 150px 0px 100px;
}
.get_started_page .text_section h1{
  font-size: 37px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.get_started_page .text_section p{
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  padding: 0px 0px 10px;
  border-bottom: 1px solid #333333;
}
.get_started_page .text_section .form_section{
  padding: 20px 0px;
}
.get_started_page .text_section .form_section .field_section{
  margin-bottom: 20px;
}
.get_started_page .text_section .form_section .field_section label{
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 8px;
}
.get_started_page .text_section .form_section .field_section input{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.get_started_page .text_section .form_section .field_section select{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.get_started_page .text_section .form_section .field_section .chakra-select__icon-wrapper{
  display: none;
}
.get_started_page .text_section .form_section .field_section .input_group_field{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  display: flex;
  padding: 8px 15px;
}
.get_started_page .text_section .form_section .field_section .input_group_field select{
  border: 0px;
  width: 20%;
}

.get_started_page .text_section .form_section .field_section .input_group_field input{
  background-color: #fff;
  width: 80%;
  border: 0px solid #333333B2;
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.get_started_page .text_section .form_section .btn_submit{
  background: linear-gradient(142.04deg, #2BB673 21.91%, #262262 292.83%);
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 15px 25px;
  width: 100%;
  margin-top: 10px;
}

/*Get Started Page End Css*/

/*Contact Us Page Start Css*/

.contact_us_page{
  background-color: #FBFBFB;
  background-image: url('./images/banner_bg_img.png');
  background-repeat: no-repeat;
  background-position: top right;
  padding: 150px 0px 100px;
}
.contact_us_page .text_section{
  width: 50%;
  margin: 0px auto 50px;
  text-align: center;
}
.contact_us_page .text_section h1{
  font-size: 37px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.contact_us_page .text_section p{
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  padding: 0px 0px 10px;
}
.contact_us_page .form_section .field_section{
  margin-bottom: 20px;
}
.contact_us_page .form_section .field_section label{
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 8px;
}
.contact_us_page .form_section .field_section input{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
 .contact_us_page .form_section .field_section textarea{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  height: 220px;
}
.contact_us_page .form_section .field_section select{
  background-color: #fff;
  width: 100%;
  border: 1px solid #333333B2;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

/*Contact Us Page End Css*/

/*Thank You Page Start Css*/

.thankyou_page{
  background-color: #FBFBFB;
  background-image: url('./images/banner_bg_img.png');
  background-repeat: no-repeat;
  background-position: top right;
  padding: 200px 0px 200px;
}

.thankyou_page .text_section{
  width: 400px;
  margin: 0px auto 50px;
  text-align: center;
}
.thankyou_page .text_section img{
  margin: 0px 0px 30px;
}
.thankyou_page .text_section h1{
  font-size: 37px;
  line-height: 50px;
  color: #333333;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin: 0px 0px 15px;
}
.thankyou_page .text_section p{
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  padding: 0px 0px 10px;
}

/*Thank You Page End Css*/


@media screen and (max-width: 767px){
  .header_section .navbar-nav{
    margin-left: 0px;
  }
  .banner_section{
    padding: 80px 0px 30px;
    background-image: unset;
  }
  .banner_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .banner_section .text_section p{
    font-size: 14px;
    line-height: 19px;
  }
  .banner_section .text_section{
    margin-bottom: 30px;
  }
  .how_it_work_section{
    padding: 30px 0px;
  }
  .how_it_work_section .main_text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .how_it_work_section .main_text_section p{
    font-size: 14px;
    line-height: 19px;
  }
  .how_it_work_section .slider_inner_section{
    flex-direction: column-reverse;
  }
  .how_it_work_section .slider_inner_section .text_section{
    width: 100%;
  }
  .how_it_work_section .slider_inner_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .how_it_work_section .slider_inner_section .text_section p{
    font-size: 14px;
    line-height: 19px;
  }
  .features_section{
    padding: 30px 0px;
  }
  .features_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .features_section .card_section{
    margin-top: 30px;
  }
  .features_section .card_section .text_section h2{
    font-size: 18px;
    line-height: 28px;
  }
  .features_section .card_section .text_section p{
    font-size: 14px;
    line-height: 19px;
  }
  .tryout_verification_section{
    padding: 40px 0px;
  }
  .tryout_verification_section .text_section{
    margin-bottom: 30px;
  }
  .tryout_verification_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .tryout_verification_section .text_section input{
    width: 100%;
  }
  .testimonial_section .main_text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .testimonial_section .main_text_section p{
    font-size: 14px;
    line-height: 19px;
  }
  .testimonial_section{
    padding: 30px 0px;
  }
  .testimonial_section .slider_inner_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .testimonial_section .slider_inner_section .text_section{
    width: 100%;
  }
  .testimonial_section .carousel-control-next .carousel-control-next-icon{
    width: 15px;
    height: 25px;
  }
  .testimonial_section .carousel-control-prev .carousel-control-prev-icon{
    width: 15px;
    height: 25px;
  }
  .cost_calculator_section{
    padding: 30px 0px;
  }
  .cost_calculator_section .text_section{
    margin-bottom: 30px;
  }
  .cost_calculator_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .start_using_section{
    padding: 30px 0px 0px;
  }
  .start_using_section .text_section{
    margin-bottom: 30px;
  }
  .start_using_section .text_section h1{
    font-size: 20px;
    line-height: 30px;
  }
  .footer_section{
    padding: 30px 0px;
  }
  .footer_section h2{
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
  }
  .contact_us_page .text_section{
    width: 100%;
  }
}


